.faq {
    padding-top: 12rem;
}

.faq h1 {
    font-weight: var(--font-weight-bold);
}

.accordion {
    padding: 4rem 0rem 8rem 0rem;
    --bs-accordion-active-color: var(--color-dark);
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(192, 159, 123, 0.25);
    ;
    --bs-accordion-active-bg: var(--color-tan);
    --bs-accordion-bg: #D1C2B5;
    --bs-accordion-border-color: var(--color-gray);
}

.accordion-item {
    background-color: var(--color-light);
}

@media screen and (max-width: 780px) {
    .faq {
        padding-top: 11rem;
    }

    .accordion {
        padding: 2rem 0rem 8rem 0rem;
    }
}