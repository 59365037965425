.nav-bar {
    box-shadow: 0px 8px 21.9px 0px rgba(55, 29, 34, 0.25);
}

.nav-bar a {
    text-align: end;
    font-family: var(--font-family-primary);
    color: var(--color-light);
    font-size: 1.5rem;
    text-decoration: none;
    position: relative; 
}

.header a.active {
    text-decoration: none; 
}

.header a.active::after {
    content: ''; 
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-light);
}

.bg {
    background-color: none;
}

.logo {
    width: 12rem;
}

.mobile-inventory-link {
    display: none !important;
}

.mobile-inventory-link a {
    font-size: 1rem;
    opacity: 0.7;
    --bs-nav-link-padding-y: 0.25rem; 
}

.header .nav-link.show,
.header .nav-link:hover,
.header .nav-link:active,
.header .nav-link:enabled {
  scale: 1.03;
}

@media screen and (max-width: 770px) {
    .mobile-inventory-link {
        display: block !important;
    }
}