.contact-us {
    padding-top: 14rem;
}

.contact-us h1 {
    font-weight: var(--font-weight-bold);
}

.contact-us-paragraph {
    padding-top: 2rem;
    padding-right: 10rem;
}

.contact-form-container {
    margin: 8rem 0rem;
    background-color: rgba(192, 159, 128, .25);
    box-shadow: 0px 8px 31px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
}

.hours-container {
    margin: 5rem;
    padding: 2rem;
    background-color: rgba(192, 159, 128, .25);
    box-shadow: 0px 8px 31px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
}

.mobile {
    display: none;
}

@media screen and (max-width: 1200px) {
    .hours-container {
        margin: 0rem;
        margin-top: 4rem;
        padding: 2rem;
    }

    .contact-us-paragraph {
        padding-top: 2rem;
        padding-right: 5rem;
    }

    .map-col {
        padding: 0rem 3rem;
    }
}

@media screen and (max-width: 1000px) {
    .hours-container {
        margin: 0rem;
        margin-top: 4rem;
        padding: 2rem;
    }

    .contact-us-paragraph {
        padding-top: 2rem;
        padding-right: 1rem;
    }

    .map-col {
        padding: 0rem 1rem;
    }
}

@media screen and (max-width: 780px) {
    .desktop {
        display: none;
    }
    
    .mobile {
        display: block;
    }

    .contact-us {
        padding-top: 10rem;
    }

    .contact-us p, .contact-us h2 {
        text-align: center;
        padding-right: 0;
        padding-top: 1rem;
    }

    .contact-form-container {
        margin-top: 3rem;
        margin-bottom: 5rem;
    }
}