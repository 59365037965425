.gallery {
    justify-content: center;
}

.gallery h2 {
    font-weight: var(--font-weight-bold);
}

.carousel {
    height: auto;
    filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.40));
}