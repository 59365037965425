.modal-content {
    --bs-modal-bg: var(--color-light);
    --bs-modal-header-border-color: var(--color-gray);
    --bs-modal-footer-border-color: var(--color-gray);
}

.model-lg {
    --bs-modal-width: 1000px!important;
}

.btn-primary {
    --bs-btn-bg: var(--color-dark);
    --bs-btn-border-color: var(--color-dark);
    --bs-btn-hover-bg: var(--color-maroon);
    --bs-btn-hover-border-color: var(--color-gray);
    --bs-btn-active-bg: var(--color-light);
    --bs-btn-active-border-color: var(--color-dark)
}

.btn-primary h4 {
    font-weight: 400;
    margin-bottom: 0;
    padding: 0.25rem 1rem;
}

.modal-body p {
    margin-top: 0.3rem;
    margin-bottom: 0.8rem;
}

.modal-content .form-control {
    background-color: #e3e3e3;
}

.id {
    font-size: small;
    font-weight: 400;
    margin-bottom: 0;
}

.modal-content input:-webkit-autofill,
.modal-content input:-webkit-autofill:hover, 
.modal-content input:-webkit-autofill:focus, 
.modal-content input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #e3e3e3 inset !important;
}

.item-form p {
    margin: 0 0;
}

/* honey-pot */
.not-today {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
