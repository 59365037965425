/* Creating Universal Styles */

body {
  margin: 0;
}

h1 {
  font-family: var(--font-family-primary);
  color: var(--color-dark);
  font-size: 6rem;
}

h2 {
  font-family: var(--font-family-primary);
  color: var(--color-dark);
  font-size: 3rem;
}

h3 {
  font-family: var(--font-family-primary);
  color: var(--color-dark);
  font-size: 1.875rem;
}

h4 {
  font-family: var(--font-family-primary);
  color: var(--color-dark);
  font-size: 1.5rem;
}

h5 {
  font-family: var(--font-family-primary);
  color: var(--color-dark);
  font-size: 1.25rem;
}

p {
  font-family: var(--font-family-secondary);
  color: var(--color-dark);
  font-size: 1rem;
}

.container {
  --max-width: 1200px;
  --padding: 1.5rem;

  width: min(var(--max-width), 100% - (var(--padding)*2));
  margin-inline: auto;
}
@media screen and (max-width: 650px) {
  .container {
    --max-width: 1200px;
    --padding: 1rem;

    width: min(var(--max-width), 100% - (var(--padding)*2));
    margin-inline: auto;
  }
}
