.inventory-cell {
    width: 15rem;
}

.card-body {
    background-color: #D1C2B5;
    border: #a7a7a7 solid 1px;
}

.card {
    filter: drop-shadow(0px 4px 27.6px rgba(0, 0, 0, 0.25));
    border: #a7a7a7 solid 1px;
}

.inventory-name-text {
    font-weight: var(--font-weight-bold);
}

.inventory-id {
    font-size: smaller;
    opacity: 75%;
}

.inventory-cell-btn {
    border: none;
    background: none;
    padding: 0;
}

.inventory-cell-btn:hover  {
    filter: drop-shadow(0px 4px 27.6px rgba(0, 0, 0, 0.2));
    scale: 1.01;
  }

  .inventory-cell-btn:hover {
    display: block;
}

  .card-img-top {
    object-fit: cover;
}