.inventory-cell-container {
    padding-top: 16rem;
    padding-bottom: 6rem;
}

.no-items-message {
    /* width: 35dvw; */
    padding: 50% 0;
}

@media screen and (max-width:770px) {
    .inventory-cell-container {
        padding-top: 10rem;
    }
}