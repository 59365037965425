/* Import Assets and Typeface */

@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Raleway&display=swap');

:root {
  --font-family-primary:  'Baskervville', serif;
  --font-family-secondary: 'Raleway', sans-serif;

  --font-weight-bold: 600;
  --font-weight-light: 400;


  --color-dark: #241B1D;
  --color-maroon: #381D22;
  --color-tan: #C09F80;
  --color-gray: #9C9590;
  --color-light: #D7CEC7;
}